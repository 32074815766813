import React, { Children, Component, createRef } from 'react';

import { Container } from 'components/layout/Container';
import { Segment } from 'components/layout/Segment';

import s from './ArticleGridMasonry.scss';

interface IProps {
  children: React.ReactNode;
  heading?: string;
  description?: string;
}

export default class ArticleGridMasonry extends Component<IProps> {

  gridNode = createRef<HTMLDivElement>();
  grid: any = null;

  static defaultProps = {
    children: undefined,
  };

  componentDidMount() {
    this.initGrid();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.children !== this.props.children) {
      this.initGrid();
      // this should be this.grid.layout()
    }
  }

  componentWillUnmount() {
    if (this.grid) {
      this.grid.destroy();
    }
  }

  initGrid = () => {
    if (!this.gridNode.current || typeof window === undefined) { return; }

    const Masonry = require('masonry-layout');

    this.grid = new Masonry( this.gridNode.current, { percentPosition: true });
  }

  render() {
    const { children, heading, description } = this.props;
    if (!children) { return null; }
    const childArray = Children.toArray(children);

    return (
      <Segment className={s.articleGrid}>
        <section>
          <Container>
            {heading && (
              <div className={s.articleGrid__lead}>
                <h1 className={s.articleGrid__heading}>{heading}</h1>
                {description && <p className={s.articleGrid__text}>{description}</p>}
              </div>
            )}
            <div className={s.articleGrid__grid} ref={this.gridNode}>
              {childArray.map((child, i) =>
                <div className={s.articleGrid__item} key={`${s.articleGrid}-${i}`}>
                  {child}
                </div>,
              )}
            </div>
          </Container>
        </section>
      </Segment>
    );
  }
}
