import React, { cloneElement, useState } from 'react';

import { Link } from 'components/link/Link';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import s from './ArticleCard.scss';

interface IProps {
  image?: React.ReactElement<any> | null;
  heading: string;
  description?: string;
  align: 'left'|'right';
  category?: string;
  link: string;
}

export const ArticleCard = ({ image, heading, category, description, link, align }: IProps) => {
  if (!image) { return null; }
  const [active, setActive] = useState(false);

  return (
    <ViewportEnter onEnter={() => setActive(true)} threshold={0}>
      <div className={s('articleCard', { active, alignRight: align === 'right', noDescription: !description })}>

        <div className={s.articleCard__imageWrap}>
          <div className={s.articleCard__imageTransition}>
            {cloneElement(image, {
              className: s.articleCard__image,
              preserveRatio: false,
            })}
          </div>
        </div>

        <div className={s.articleCard__content}>
          <div className={s.articleCard__contentInner}>
            <h2 className={s.articleCard__heading}>
              <Link className={s.articleCard__link} to={link} tabIndex={0}>{heading}</Link>
            </h2>
            {category && (<h3 className={s.articleCard__kicker}>{category}</h3>)}
            {description && <div className={s.articleCard__description}>{description}</div>}
            </div>
        </div>
      </div>
   </ViewportEnter>
  );
};

ArticleCard.defaultProps = {
  align: 'left',
  link: '#',
};
